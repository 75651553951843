import React from 'react';
import styled, { css } from 'styled-components';
import { Upload as upload, Spin, Icon } from 'antd';
import { Notification } from 'components';
import Compressor from 'compressorjs';

const Upload = styled(upload)`
  .ant-upload-select-picture-card {
    display: flex;
    height: 35px;
    width: 35px;
  }

  ${props =>
    props.hideUpload &&
    css`
      .ant-upload-select-picture-card {
        display: none;
      }
    `}
`;

const UploadVideo = styled(upload)`
  .ant-upload-list {
    display: flex;
    flex-wrap: wrap;
    div {
      flex-basis: 33.33%;
      @media (max-width: 992px) {
        flex-basis: 100%;
      }
    }
  }
  ${props =>
    props.hideUpload &&
    css`
      .ant-upload-select-picture-card {
        display: none;
      }
    `}
`;

const MediaUpload = ({
  fileList = [],
  changeHandler,
  loading,
  extensions,
  maxAmount,
  multiple,
  onPreview,
  fileSize = 25,
  hideUpload,
  setImage,
  setIsVisible,
  files,
  otherFiles,
  ...otherProps
}) => {
  const imgMimeType = ['image/jpeg', 'image/jpg', 'image/png'];

  // const allowExt = [
  //   'video/avi',
  //   'video/mp4',
  //   'video/flv',
  //   'video/WMV',
  //   'video/WEBM',
  //   'application/pdf',
  //   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  //   'application/vnd.ms-excel',
  //   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  //   'image/jpeg',
  //   'image/jpg',
  //   'image/png',
  // ];
  const videoFileExt = ['avi', 'mp4', 'flv', 'WMV', 'WEBM', 'pdf', 'docx', 'xls', 'xlsx'];

  const addFile = (file, files) => {
    Promise.all(
      files.map(f => {
        // eslint-disable-next-line no-unused-expressions
        if (imgMimeType.includes(f.type)) {
          return new Promise((resolve, reject) => {
            // eslint-disable-next-line no-new
            new Compressor(f, {
              quality: f.size > 2 ? 0.2 : 0.6,
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(err);
              },
            });
          });
        }
        return f;
      })
    ).then(
      selectedFileList => {
        // const validExtension = selectedFileList?.some(ex => allowExt.includes(ex.type));
        const hasBigFiles = selectedFileList.find(f => f.size > fileSize * 1024 * 1024);
        // const hasRestrictedExtensions = !!selectedFileList.filter(
        //   f =>
        //     extensions &&
        //     !extensions.includes(
        //       f.name
        //         .split('.')
        //         .pop()
        //         .toLowerCase()
        //     )
        // ).length;
        if (
          (!multiple && fileList.length >= 1) ||
          fileList.length + selectedFileList.length > maxAmount ||
          hasBigFiles
        ) {
          Notification.warning(
            `Maximum ${maxAmount || (!multiple && 1)} files allowed, ${fileSize} MB each`
          );
        }
        //  else if (!validExtension) {
        //   Notification.warning('Unsupported extension!');
        // }
        else {
          changeHandler &&
            changeHandler(selectedFileList, [...fileList, ...selectedFileList], 'add');
        }
      },
      reason => {
        Notification.warning(reason);
      }
    );

    return false;
  };

  const deleteFile = file => {
    changeHandler &&
      changeHandler(
        file,
        fileList.filter(f => f.uid !== file.uid),
        'delete'
      );
  };

  return (
    <>
      <Spin spinning={!!loading}>
        <UploadVideo
          listType='text'
          fileList={otherFiles}
          onPreview={e => {
            const isVideo = videoFileExt?.some(ex => e?.name.includes(ex));

            if (isVideo) {
              window.location.replace(e.url);
            } else {
              setImage(e);
              setIsVisible(true);
            }
          }}
          beforeUpload={addFile}
          onRemove={deleteFile}
          hideUpload={hideUpload}
          multiple={multiple}
          showUploadList
          {...otherProps}
        />
        <Upload
          listType='picture-card'
          fileList={fileList?.filter(e => e.name === 'blob')}
          onPreview={e => {
            const isVideo = videoFileExt?.some(ex => e?.name.includes(ex));

            if (isVideo) {
              window.location.replace(e.url);
            } else {
              setImage(e);
              setIsVisible(true);
            }
          }}
          beforeUpload={addFile}
          onRemove={deleteFile}
          hideUpload={hideUpload}
          multiple={multiple}
          showUploadList
          {...otherProps}
        >
          <div>
            <div className='ant-upload-text'>
              <Icon type='camera' style={{ fontSize: '15px', margin: '0px', color: '#D9D9D9' }} />
            </div>
          </div>
        </Upload>
      </Spin>
    </>
  );
};

export default React.memo(MediaUpload);
