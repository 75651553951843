import styled, { css } from 'styled-components';
import { Modal as modal } from 'antd';
import propTypes from 'prop-types';

const Modal = styled(modal)`

  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin}!important;
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}!important;
    `}
  ${props =>
    props.body_height &&
    css`
      .ant-modal-body {
        height: ${props.body_height};
      }
    `}
    ${props =>
      props.body_width &&
      css`
        .ant-modal-content {
          width: ${props.body_width} !important;
        }
      `}
  ${props =>
    props.body_max_height &&
    css`
      .ant-modal-body {
        max-height: ${props.body_max_height};
      }
    `}
    ${props =>
      props.body_min_height &&
      css`
        .ant-modal-body {
          min-height: ${props.body_min_height};
        }
      `}

      ${props =>
        props.overflow &&
        css`
          .ant-modal-body {
            overflow: ${props.overflow};
          }
        `}
  ${props =>
    props.hideCancel &&
    css`
      .ant-modal-footer {
        div {
          button:first-child {
            display: none;
          }
        }
      }
    `}

  ${props =>
    props.mode === 'full-screen' &&
    css`
      border-radius: none !important;
      border: none;
      .ant-modal-content {
        box-shadow: none !important;
      }
      .ant-modal-body {
        padding: 0;
      }
    `}
  .custom-actions {
    width: 100%;
    text-align: right;
    background: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 99999999;
    padding: 5px 15px;

    button:first-child {
      margin-right: 10px;
    }
  }

  .ant-modal-content {
    border-radius: 3px;
  }
`;

Modal.propTypes = {
  mode: propTypes.string,
  wrapClassName: propTypes.string,
  maskStyle: propTypes.object,
};

Modal.defaultProps = {
  mode: 'normal',
  wrapClassName: 'styled-modal',
  maskStyle: {
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
};
/** @component */
export default Modal;
