import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { isUserPermitted } from 'configs/isUserPermitted';
import { roles } from 'constants/users';
import { Icon } from 'antd';
// eslint-disable-next-line import/no-cycle
import { Button, Modal, Notification, Drawer } from 'components';
import { getAddBookingLoading, getSuccessState } from 'selectors/bookings';
import { cleanAll } from 'actions/booking-form';
import HistoryLog from 'containers/HistoryLog/index';
import { getUserData } from 'selectors/users';
import { updateUsersApi, getUserApi } from 'services/users';
import useBreakpoint from 'utils/useBreakpoint';
import BookingModalContact from './_bookingmodalcontent';

const AddBooking = styled.div`
  display: inline-block;
  margin-right: 16px;
  @media (max-width: 767px) {
    margin-right: 0;
  }
`;

const AddBookingComponent = () => {
  const isMobile = useBreakpoint();
  const dispatch = useDispatch();
  const addBookingLoading = useSelector(getAddBookingLoading);
  const userData = useSelector(getUserData);
  const success = useSelector(getSuccessState);
  const [isVisible, setIsVisible] = useState(null);

  const [modalVisible, setModalVisible] = useState(false);
  const [isHistory, setIsHistory] = useState();

  useEffect(() => {
    if (!addBookingLoading && typeof addBookingLoading !== 'undefined' && success) {
      setModalVisible(false);
      dispatch(cleanAll.request());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addBookingLoading, success]);

  const closeModal = () => {
    setModalVisible(false);
  };
  useEffect(() => {
    if (!addBookingLoading && typeof addBookingLoading !== 'undefined' && success) {
      Notification.info('Booking created');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  useEffect(() => {
    getUserApi({ id: userData?.id }).then(res => setIsHistory(res?.data?.isHistoryRead));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHistory]);
  return (
    <>
      {isUserPermitted([roles.ROLE_MANAGER, roles.ROLE_SINGLE_INSPECTOR]) && (
        <AddBooking>
          <Button type={'primary'} onClick={() => setModalVisible(true)}>
            <Icon type='plus-circle' /> New Booking
          </Button>
          <Button
            style={{
              marginLeft: '20px',
              width: '20px',
              borderRadius: '40px',
              position: 'relative',
            }}
            onClick={() => {
              setIsVisible(true);
              updateUsersApi({
                id: userData?.id,
                values: { isHistoryRead: false },
              }).then(() => setIsHistory(false));
            }}
          >
            <Icon type='notification' style={{ color: '#AAAAAA', marginLeft: '-7px' }} />
            {isHistory && (
              <div
                style={{
                  position: 'absolute',
                  top: '-1px',
                  right: '1px',
                  width: '8px',
                  height: '8px',
                  borderRadius: '50%',
                  background: '#3535dd',
                }}
              />
            )}
          </Button>
          {modalVisible && (
            <Modal
              bodyStyle={{ height: '100%' }}
              title={''}
              footer={null}
              width={isMobile ? '100%' : '80%'}
              visible={modalVisible}
              onCancel={() => {
                closeModal();
                dispatch(cleanAll.request());
              }}
              closable
              maskClosable={false}
              destroyOnClose
            >
              <BookingModalContact
                visibility={modalVisible}
                mode='create'
                closeModal={closeModal}
                modalVisible={modalVisible}
              />
            </Modal>
          )}
        </AddBooking>
      )}

      <Drawer
        title="What's new on Inspector"
        placement='right'
        closable={false}
        onClose={() => setIsVisible(false)}
        visible={isVisible}
        width={window.innerWidth < '992' ? '80%' : '40%'}
      >
        <HistoryLog />
      </Drawer>
    </>
  );
};

export default AddBookingComponent;
